<template>
    <div id="app">
        <div v-if="rootStore.globalError" id="global-error" v-text="rootStore.globalError" />

        <Login v-else-if="!rootStore.user" />
        <router-view v-else />

        <OverlayContainer />
        <div v-if="isDevelopment" class="dev-banner">Development Environment</div>
    </div>
</template>

<script lang="ts" setup>
import { OverlayContainer } from '@signal24/vue-foundation';

import Login from './components/login.vue';
import { useRequestNotificationPermission } from './shared/services/notifications';
import { initStore, useStore } from './store';

const rootStore = useStore();
initStore();

const isDevelopment = import.meta.env.DEV;

useRequestNotificationPermission();
</script>

<style lang="scss">
@use './shared/styles/base.scss' as *;

#app {
    @apply max-w-full flex-1 flex items-center justify-center;
}

#global-error {
    @apply flex-1 flex justify-center items-center text-2xl text-red-600 whitespace-pre-line text-center;
}

.dev-banner {
    @apply absolute bottom-0 left-0 w-full text-center p-4 text-red-500 text-3xl;
}
</style>
