import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/vue';
import { OpenApiError } from '@signal24/openapi-client-codegen/browser';
import { configureVfOpenApiClient, UserError } from '@signal24/vue-foundation';

import { client } from './generated/openapi-client';
import { Auth } from './shared/services/auth';
import { logger } from './shared/services/logger';
import { OpenReplay } from './shared/services/openreplay';

client.setConfig({
    baseUrl: import.meta.env.VITE_APP_API_URL
});

configureVfOpenApiClient(client, {
    headers() {
        return {
            Authorization: `Bearer ${Auth.activeJwt}`,
            'x-openreplay-sessionid': OpenReplay.sessionId ?? undefined
        };
    },

    onError(err) {
        if (err instanceof OpenApiError) {
            if (
                err.response.status === 400 &&
                err.body &&
                typeof err.body === 'object' &&
                'message' in err.body &&
                typeof err.body.message === 'string' &&
                err.body.message.includes('phoneNumber(invalidPhone)')
            ) {
                return new UserError('The phone number provided is invalid.');
            }

            if (err.response.status === 401) {
                Auth.clearSession();
                return err;
            }
        }

        if (!(err instanceof UserError)) {
            Sentry.withScope(scope => {
                const spanCtx = trace.getActiveSpan()?.spanContext();
                if (spanCtx) {
                    scope.setContext('trace', {
                        trace_id: spanCtx.traceId,
                        span_id: spanCtx.spanId
                    });
                }
                Sentry.captureException(err);
            });
        }
    }
});

const originalFetch = window.fetch;
window.fetch = async (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response> => {
    const url = input.toString();
    if (url === import.meta.env.VITE_APP_SCRIBE_URL) {
        return originalFetch(input, init);
    }

    try {
        const url = input.toString();
        logger.info('[API]', 'Request', {
            method: init?.method ?? 'GET',
            url
        });
        const result = await originalFetch(input, init);
        logger.info('[API]', 'Response', {
            method: init?.method,
            url,
            status: result.status
        });
        return result;
    } catch (err) {
        logger.error('[API]', 'Error', err);
        throw err;
    }
};
