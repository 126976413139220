<template>
    <Loader />
    <!-- <form v-else action="https://auth.zynoapi.net/proxy-oidc/start" method="POST" @submit="doLogin">
        <input type="hidden" name="returnUrl" :value="returnUrl" />
        <input type="email" name="email" placeholder="e-mail address" v-autofocus />
        <button type="submit">Login</button>
    </form> -->
</template>

<script lang="ts" setup>
import { dataFrom, OpenApiError } from '@signal24/openapi-client-codegen/browser';
import { formatError } from '@signal24/vue-foundation';
import { onMounted } from 'vue';

import { IdentityApi } from '@/generated/openapi-client';
import Loader from '@/shared/components/loader.vue';
import { Auth } from '@/shared/services/auth';
import { useStore } from '@/store';

const store = useStore();

onMounted(async () => {
    try {
        const response = await fetch('https://agentapp.c2cfsi.com/jwt', {
            redirect: 'manual',
            credentials: 'include'
        });

        if (response.type === 'opaqueredirect' && location.host.startsWith('localhost')) {
            store.globalError = 'Please sign into https://agentapp.c2cfsi.com first.';
            return;
        }

        if (!response.ok) {
            throw new Error(`HTTP request failed with status ${response.status}`);
        }

        const { jwt } = await response.json();
        Auth.setJwt(jwt);

        const identity = dataFrom(await IdentityApi.getIdentityGetIdentity());
        store.user = identity;
    } catch (err) {
        store.globalError =
            err instanceof OpenApiError && err.response.status === 401 ? 'You are not authorized to access this system.' : formatError(err);
    }
});
</script>

<style lang="scss" scoped>
form {
    @apply w-96 bg-gray-100 p-2 rounded-md flex gap-1;

    input {
        @apply flex-1;
    }
}
</style>
